<template>
    <div class="grid">
      <div class="col-12">
        <div class="card">
          <Toast />
          <Toolbar class="mb-4">
            <template v-slot:start>
              <div class="my-2">

                 
              </div>
            </template>
  
            <template v-slot:end>
              <Button
                label="Exportar"
                icon="pi pi-upload"
                class="p-button-help"
                @click="exportCSV($event)"
              />
            </template>
          </Toolbar>
  
          <DataTable
            ref="dt"
            :value="ventas"          
            dataKey="id"
            :paginator="true"
            :rows="10"
            :filters="filters"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 25]"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} facturas"
            responsiveLayout="scroll"
            class="p-datatable-sm"
            :rowClass="rowClass"
            v-model:expandedRows="expandedRows" 
              @rowExpand="onRowExpand" @rowCollapse="onRowCollapse"  
  
          >
            <template #header>
              <div
                class="
                  flex flex-column
                  md:flex-row md:justify-content-between md:align-items-center
                "
              >
                <h5 class="m-0">Facturas emitidas</h5>
                <span class="block mt-2 md:mt-0 p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText
                    v-model="filters['global'].value"
                    placeholder="Buscar..."
                  />
                </span>
              </div>
            </template>
            <!-- <Column :expander="true" headerStyle="width: 1rem" /> -->
            <Column
              field="cbt_tipo"
              header="#"
              :sortable="true"
              headerStyle="width:5%; min-width:7rem;"
            >
              <template #body="slotProps">
                <span class="p-column-title">#</span>
                {{ fac_types[slotProps.data.cbt_tipo] }}
              </template>
          </Column>
          <Column
              field="numero"
              header="#"
              :sortable="true"
              headerStyle="width:5%; min-width:7rem;"
            >
              <template #body="slotProps">
                <span class="p-column-title">#</span>
                {{ slotProps.data.numero }}
              </template>
          </Column>
  
            <Column
              field="date"
              header="Fecha"
              :sortable="true"
              headerStyle="width:10%; min-width:10rem;"
            >
              <template #body="slotProps">
                <span class="p-column-title">Fecha</span>
                {{ formatDate(slotProps.data.created_at) }}
              </template>
          </Column>
    <Column
              field="fecha_venc_pago"
              header="Venc. Pago."
              :sortable="true"
              headerStyle="width:10%; min-width:7rem;"
            >
              <template #body="slotProps">
                <span class="p-column-title">Venc. Pago.</span>
                {{ slotProps.data.fecha_venc_pago }}
              </template>
          </Column> 
  
            <Column
              field="client_name"
              header="Cliente"
              :sortable="true"
              headerStyle="width:20%; min-width:4rem;"
            >
              <template #body="slotProps">
                <span class="p-column-title">Cliente</span>
                {{ slotProps.data.rel_model.client_name}} 
              </template>
          </Column>
          <Column
              
              header="Numero O.C."
              :sortable="true"
              headerStyle="width:30%; min-width:10rem;"
            >
              <template #body="slotProps">
                <span class="p-column-title">OC Number</span>
                <a href="#" @click="this.$router.push({name: 'ventas', params: {id: slotProps.data.rel_model.id}})">

                    {{ slotProps.data.rel_model.oc_number }}
                </a>
              </template>
          </Column>
          <Column
              field="total"
              header="Total"
              :sortable="true"
              headerStyle="width:20%; min-width:10rem;"
            >
              <template #body="slotProps">
                <span class="p-column-title">Total</span>
                {{ toArs(slotProps.data.imp_total) }}
              </template>
          </Column>
          <Column
              header="Recibido"
              :sortable="true"
              headerStyle="width:20%; min-width:10rem;"
            >
              <template #body="slotProps">
<div class="flex justify-content-around">

    <div>
        
        {{toArs(recTotal(slotProps.data.rel_model.recibos))}}
    
    </div>
    
    <div>

                    
                    <Button
                    icon="pi pi-plus"
                    class="p-button-rounded p-button-success mr-2"
                    @click="addPagoToFactura(slotProps.data.id)"
                    />
                </div>
                </div>


              </template>


          </Column>
              <!-- <Column
                  field="status"
                  header="Estado"
                  :sortable="true"
                  headerStyle="min-width:10rem;"
                  >
                  <template #body="slotProps">
                  <div v-if="slotProps.data.status == 0">
                    <Button class="p-button-success" @click="goToProduction(slotProps.data.id)">
                      <i class="pi pi-cog mr-2"></i> ENVIAR A  PRODUCCIÓN
                    </Button>
                  </div>  
                  </template>
              </Column> -->
            <Column headerStyle="min-width:10rem;">
              <template #body="slotProps">
                    <!-- <Button class="p-button-success mr-2" v-if="slotProps.data.status == 0" @click="goToProduction(slotProps.data.id)">
                      <i class="pi pi-cog mr-2"></i> Todo
                    </Button> -->
                
                <Button
                  icon="pi pi-file-pdf"
                  class="p-button-rounded p-button-danger mr-2"
                  @click="showPdf(slotProps.data.numero + '.pdf')"
                  v-if="slotProps.data.numero"
                />

                
              </template>
            </Column>
            <template #expansion="slotProps">
                  <div class="orders-subtable">
  <!-- 
                     <h5>Items en # {{slotProps.data.oc_number}} de {{ slotProps.data.client_name }}</h5> -->
                      <DataTable :value="slotProps.data.items" responsiveLayout="scroll"
                      :rowClass="rowClassItem"
                      >
                        <Column  header="Item" sortable>
                          <template #body="slotProps">
                            <span class="p-column-title">Item</span>
                            {{ slotProps.index + 1 }}
                          </template>
                        </Column>
                          <Column field="qty" header="Cant." sortable></Column>
                          <Column field="piece_code" header="Código" sortable></Column>
                          <Column field="name" header="Pieza" sortable></Column>
                          <Column field="mp_to_use" header="M.P." sortable>
                            <template #body="slotProps" sortable>
                              <span class="p-column-title">M.P.</span>
                              {{ slotProps.data.mp_to_use }}
                              <div v-if="slotProps.data.mpc"> M.P. a cargo del cliente.</div>
                            </template> 
                        </Column>
                          
                          <Column field="valor" header="$" sortable>
                              <template #body="slotProps" sortable>
                                  {{ toArs(slotProps.data.valor) }} 
                              </template>
                          </Column>
  
                          <Column field="fecha_prevista" header="Fecha Prevista" sortable>
                          </Column>
  
                          <Column >
                              <template #body="slotProps" >
                                <Button class="p-button-sm p-button-success mr-1" v-if="slotProps.data.inprod == 0" @click="goToProductionOne(slotProps.data.id)">
                      <i class="pi pi-cog mr-2"></i> PRODUCIR 
                    </Button>
                    <Button class="p-button-sm p-button-info mr-2" v-if="slotProps.data.inprod !== 0" @click="goOti(slotProps.data.inprod)">
                      <i class="pi pi-eye mr-2"></i> VER ORDEN 
                    </Button>
                              </template>
                          </Column> 
                      </DataTable> 
                  </div>
              </template>
  
          </DataTable>
  
  
          <Dialog
            v-model:visible="pagoDialog"
            :style="{ width: '450px' }"
            header="Agregar Pago"
            :modal="true"
          >
            <div class="formgrid grid">
<!-- <em>

  {{ selectedFactura }} ... ID FACTURA
</em> -->

                <br>

                Tipo de pago:
                <Dropdown
                  v-model="pago.tipo"
                  :options="tiposPago"
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Seleccione"
                  class="p-mr-2"
                  style="width: 100%"
                />
                <br>

                Monto:
                <InputNumber
                  v-model="pago.monto"
                  mode="currency"
                  currency="ARS"
                  locale="es-AR"
                  :min="0"
                  :precision="2"
                  :showButtons="true"
                  :buttonLayout="stacked"
                  :localeMatcher="lookup"
                  :currencyDisplay="symbol"
                  :style="{ width: '100%' }"
                />

                <br>
              <div  class="field"  v-if="pago.tipo !== 1">
              
              
                

                <label for="formu" v-if="pago.tipo == 3">Nro de Cheque:</label>
               
                <label for="formu" v-if="(pago.tipo == 2)">Ref:</label>
                <InputText v-model="pago.ref"  class="inputfield w-full"
                
                :style="{ width: '100%' }"
                />

              </div>
              <div  class="field" v-if="pago.tipo !== 1">
              
              
                

              <label for="formu" v-if="(pago.tipo != 1)">Banco:</label>
             
              <InputText v-model="pago.banco"  class="inputfield w-full"
              
              :style="{ width: '100%' }"
              />
              <div v-if="pago.tipo == 3" class="field">
              
              
                

              <label for="formu">Fecha de cobro:</label>
             
              <InputText v-model="pago.acobrar"  class="inputfield w-full"
              
              :style="{ width: '100%' }"
              />

            </div>
            </div>
                <br>
            </div>
            <template #footer>
              <Button
                label="No"
                icon="pi pi-times"
                class="p-button-text"
                @click="pagoDialog = false"
              />
              <Button
                label="Sí"
                icon="pi pi-check"
                class="p-button-text"
                @click="addPayment()"
              />
            </template>
          </Dialog>
  
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { FilterMatchMode } from "primevue/api";
  import CrudService from "./../../services/crud.service";
  import otiService from '../../services/oti.service';
  
  export default {
    data() {
      return {
        selectedFactura: null,
        pagoDialog: false,
        routeName: null,
        pago: {
          tipo: null,
          monto: null,
        },
        tiposPago: [
          { id: 1, name: "Efectivo" },
          { id: 2, name: "Transferencia" },
          { id: 3, name: "Cheque" },
        ],
        ventas: [
         
        ],
        fac_types:{
            '1':'Factura A',
            '6':'Factura B',
            '11':'Factura C',

        },
        expandedRows: [],
        productDialog: false,
        deleteProductDialog: false,
        deleteProductsDialog: false,
        product: {},
        selectedProducts: null,
        filters: {},
        submitted: false,
        validationErrors: null,
        providers: []
      };
    },
     
    created() {
      // get route name
      this.routeName = this.$route.name;
       
      this.initFilters();
    },
    mounted() {
       CrudService.getCRUD('api/facturacion').then(
         (data) => (this.ventas = data)
      ).catch(
        (error) => console.log(error)
      );
      // CrudService.getCRUD('api/clients').then(
      //   (data) => (this.providers = data)
      // );
  
    },
    methods: {
        recTotal(recibos){

            let total = 0;
            recibos.forEach(recibo => {
                total += Math.round(recibo.monto);
            });
            return total;
        },

        addPayment(){


            if(this.pago.tipo == null || this.pago.monto == null){
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Debe completar todos los campos', life: 3000 });
                return;
            }

            let data = {
                'factura_id': this.selectedFactura,
                'tipo': this.pago.tipo,
                'monto': this.pago.monto,
            }

            CrudService.postCRUD('api/facturacion/addPayment', data).then(
                (data) => {
                    if(data.status == 'success'){
                        this.$toast.add({ severity: 'success', summary: 'Éxito', detail: data.message, life: 3000 });
                        this.pagoDialog = false;
                        this.pago = {
                            tipo: null,
                            monto: null,
                        }
                        CrudService.getCRUD('api/facturacion').then(
                            (data) => (this.ventas = data)
                        ).catch(
                            (error) => console.log(error)
                        );
                    }
                }
            ).catch(
                (error) => console.log(error)
            );


            this.pagoDialog = false;
            this.pago.tipo = null;
            this.pago.monto = null;
        },
        addPagoToFactura(id){
          //alert(id);
          this.pago.tipo = null;
            this.pago.monto = null;

          this.selectedFactura = id;
            this.pagoDialog = true;

        },
        formatDate(value){
      // format date to dd-mm-yyyy
      if (value) {
        let date = new Date(value);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        return day.toString().padStart(2, '0') + '/' + month.toString().padStart(2, '0') + '/' + year;
      }
      
    },
      goToFacture(data) {
        this.$router.push({ name: "facturar", params: { id: data.id } });
      },
      onRowExpand(event) {
        
        console.log(event.data.id)
        // put false in others index
        this.expandedRows.forEach((item, index) => {
          if(item.id != event.data.id) {
            this.expandedRows[index] = false;
          }
        });
   
  
  
  
  
  
  
          },
          onRowCollapse(event) {
              //this.$toast.add({severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000});
          },
          goToProductionOne(id) {
  
  otiService.pieceToProduction(id).then(
    (data) => {
      console.log(data)
      // router go to production
       this.$router.push("/orden_config/"+data);
  
    }
  ).catch(
    (error) => {
      //this.showMessage('error', 'Error al enviar a producción');
      console.log(error)
    }
  );
  
  },
  goOti(id){
    this.$router.push("/orden/"+id);
  },
      goToProduction(id) {
  
        otiService.ocToProduction(id).then(
          (data) => {
            console.log(data)
            // router go to production
             this.$router.push("/produccion/"+data);
  
          }
        ).catch(
          (error) => {
            //this.showMessage('error', 'Error al enviar a producción');
            console.log(error)
          }
        );
  
      },
      accept(budget){
        console.log(budget.id)
        CrudService.updateCRUD('api','cotizacion/accept', budget).then(
          (data) => {
            const index = this.cotizaciones.findIndex(
              (item) => item.id === budget.id
            );
  
            // update budget in array
            this.cotizaciones[index] = data.data;
          }
        ).catch(
          (error) => console.log(error)
        );
      
      },
      reject(budget){
        CrudService.updateCRUD('api','cotizacion/reject', budget).then(
          (data) => {
            // find index of budget in array
            const index = this.cotizaciones.findIndex(
              (item) => item.id === budget.id
            );
  
            // update budget in array
            this.cotizaciones[index] = data.data;
          }
        ).catch(
          (error) => console.log(error)
        );
      
      },
      
      statusName(value){
  
        // convert value to integer
        value = parseInt(value);
  
        switch (value) {
  
          case 0:
            return 'Pendiente';
            break;
          case 1:
            return 'Enviada';
            break;
          case 2:
            return 'Vencida';
            break;
          case 3:
            return 'Aprobada';
            break;
          case 4:
            return 'Rechazada';
            break;
          default:
            return 'Pendiente';
            break;
          }
      },
      giveMeName(id) {
        let exist = this.providers.find(provider => provider.id === Number(id));
  
        if(!exist) {
          return ' - ';
        }else {
          return exist.name;
        }
      },
      afipType(value) {
        if (value == 1) {
          return "Responsable Inscripto";
        }
        if (value == 2) {
          return "Monotributista";
        }
        if (value == 3) {
          return "Exento";
        }
        if (value == 4) {
          return "Consumidor Final";
        }
  
        return " - ";
      },
      formatCurrency(value) {
        if (value)
          return value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          });
        return;
      },
      openNew() {
        // go to route cotizaciones/create
        this.$router.push({ name: "CreateSell" });
      },
      hideDialog() {
        this.productDialog = false;
        this.submitted = false;
      },
      displayErrors(errors) {
        
                for (let key in errors) {
  
                  this.validationErrors.push({ "field": key, "message": errors[key] });
  
                  let error = errors[key];
                  this.$toast.add({
                    severity: "error",
                    summary: "Error!",
                    detail: error[0],
                    life: 3000,
                  });
                }
      },
      toArs(value) {
   value = Number ( value )
   return value.toLocaleString("es-AR", {
     style: "currency",
     currency: "ARS",
   });
  }, 
      saveProduct() {
        this.submitted = true;
        if (this.product.name.trim()) {
          if (this.product.id) {
            
  
            CrudService.updateCRUD(this.routeName, this.routeName, this.product).then(
              (data) => {
  
              this.validationErrors = [];
              if (data.status == 400) { 
  
                this.displayErrors(data.data);
  
              } else {
                this.$toast.add({
                  severity: "success",
                  summary: "Éxito!",
                  detail: "Actualizado correctamente.",
                  life: 3000,
                });
                this.products[this.findIndexById(this.product.id)] = this.product;
                this.productDialog = false;
                this.product = {};
              }
            });
          } else {
            this.product.quantity =1
            CrudService.createCRUD(this.routeName, this.routeName, this.product).then(
              (data) => {
                
              this.validationErrors = [];
              if (data.status == 400) { 
  
                this.displayErrors(data.data);
                
                } else {
                  this.products.push(this.product);
                  this.$toast.add({
                    severity: "success",
                    summary: "Éxito!",
                    detail: "Creado correctamente.",
                    life: 3000,
                  });
                  this.productDialog = false;
                  this.product = {};
                }
              }
            );
          }
        }
      },
      editProduct(product) {
        // this.product = { ...product };
        // this.productDialog = true;
  
        alert('en desarrollo')
      },
      showPdf(id) { 
  
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        let url = this.appBackend + `/show_fac/${id}/`;
  
        // new window
        anchor.target = "_blank";
        anchor.href = url;
  
        // click
        anchor.click();
  
  
  
      },
      showPdf(url) { 
  
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        let file = this.appBackend + `/show_fac/${url}`;
  
        // new window
        anchor.target = "_blank";
        anchor.href = file;
  
        // click
        anchor.click();
  
  
  
  
      },
      confirmDeleteProduct(product) {
        this.product = product;
        this.deleteProductDialog = true;
      },
      deleteProduct() { 
  
        CrudService.deleteCRUD('api', 'ventas', this.product.id).then(
          (data) => {
            
            this.$toast.add({
              severity: "success",
              summary: "Éxito!",
              detail: "Eliminado correctamente.",
              life: 3000,
            });
          }
        );
  
        this.cotizaciones = this.ventas.filter((val) => val.id !== this.product.id);
  
        this.deleteProductDialog = false;
        this.product = {};
  
      },
      findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.products.length; i++) {
          if (this.products[i].id === id) {
            index = i;
            break;
          }
        }
        return index;
      },
      createId() {
        let id = "";
        var chars =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < 5; i++) {
          id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
      },
      exportCSV() {
        this.$refs.dt.exportCSV();
      },
      confirmDeleteSelected() {
        this.deleteProductsDialog = true;
      },
      deleteSelectedProducts() {
        this.products = this.products.filter(
          (val) => !this.selectedProducts.includes(val)
        );
        this.deleteProductsDialog = false;
        this.selectedProducts = null;
        this.$toast.add({
          severity: "success",
          summary: "Éxito!",
          detail: "Eliminado correctamente.",
          life: 3000,
        });
      },
      initFilters() {
        this.filters = {
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        };
      },
      rowClass(data) {
        return 'tr-'+data.status
      },
      rowClassItem(data) {
  
            if(data.inprod){
              return 'tr-green'
            }else{
              return 'tr-yellow'
            }
  
          }
    },
  };
  </script>
  
   
  <style scoped>
  
          .status_box {
            display: inline-block;
            line-height: 2rem;
                width: 100%;
            font-weight: bolder;
            text-align: center;
            padding: 5px;
            text-transform: uppercase;
          }
          .status-4 {
              border: 1px solid #74171171;
              background-color: #cc050571;
          }
          .status-3 {
              border: 1px solid #44b73a71;
              background-color: #44b73a71;
          }
          .status-2 {
              border: 1px solid #ff99005b;
              background-color: #ff99005b;
          }
          .status-1 {
              border: 1px solid #1c96c256;
              background-color: #1c96c256;
            
          }
          .status-0 {
              border: 1px solid #d8d8d89a;
              background-color: #d8d8d89a;
          }
  
          .orders-subtable {
              /*padding: 1rem;*/
          }
  
  </style>
  <style>
          .p-datatable-row-expansion {
      background: rgb(229, 234, 240) !important;
  }
  
  .tr-0{
      background: #d8d8d89a !important;
       
  }
  .tr-green{
      background: #44b73a71 !important;
       
  }
  .tr-yellow{
      background: #ff99005b !important;
  
       
  }
  
  </style>